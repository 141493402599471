body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.simple-form .banner-html .ql-container {
  display: block;
  margin-bottom: 40px;
}
.simple-form .banner-html .ql-tooltip {
  left: 0 !important;
}
.tokenex_iframe__Jcknq {
  height: 1rem;
  display: inline;
}

.tokenex_iframe__Jcknq iframe {
  margin-top: 1px;
  max-width: 70%;
  max-height: calc(1rem + 1px);
  display: inline;
  overflow: visible;
  line-height: 1rem;
  font-size: 1rem;
}

.addressField_address__1-zlF {
  padding-top: 24px;
}

.fullServiceMoveCancel_button__5Yqpm {
  color: #f44336 !important;
}

.fullServiceMoveGrid_checkboxArea__1hMcD {
  width: 56px;
  text-align: center;
}

.fullServiceMove_hide__3hU88 {
  display: none !important;
}
.fullServiceMove_deleteCancelButton__1chz_ {
  margin-left: auto !important;
}

